section.faq {

  & > .title {
    font-size: 63px;
    margin-bottom: 53px;

    @media (max-width: 1024px) {
      font-size: 60px;
    }

    @media (max-width: 991px) {
      font-size: 44px;
      margin-bottom: 24px;
    }
  }

  .item{
    padding: 35px 0 15px 0;
    border-top: 1px solid $faq-border;
    overflow: hidden;

    @media (max-width: 991px) {
      padding: 30px 0 15px 0;
    }

    .title {
      position: relative;
      @include typo-faq-title;
      cursor: pointer;
      padding-right: 23px;

      @media (max-width: 991px) {
        font-size: 20px;
        line-height: 24px;
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 13px;
        right: 0;
        width: 24px;
        height: 2px;
        background-color: #000;
        transition: transform 0.3s ease;

        @media (max-width: 768px) {
          width: 20px;
        }
      }

      &:after {
        transform: rotate(-90deg);
      }
    }
    .text {
      position: relative;
      max-height: 0;
      margin-top: 20px;
      @include typo-faq-text;
      transition: max-height 0.7s ease;
      overflow: hidden;

      @media (max-width: 991px) {
        font-size: 20px;
        line-height: 30px;
      }

      p {
        margin-bottom: 1rem;
      }
    }

    &.active {
      .title:after {
        transform: rotate(0deg);
      }
    }
  }
}