body {
  @include typo-base;

  @media (min-width: 1025px) {
    font-size: clamp(20px, calc(.05rem + 1.56vw), 1.625rem);
  }

  @media (max-width: 1024px) {
    font-size: 23px;
  }

  @media (max-width: 991px) {
    font-size: 20px;
  }
}

a {
  @include typo-anchor;
  text-decoration: none;
  position: relative;

  &:not(.btn, .icon, .underline-none) {
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 0;
      height: 2px;
      width: 100%;
      background-color: $green;
      transition: max-width 0.3s ease;
    }

    &:hover {
      &:before {
        max-width: 100%;
      }
    }
  }
}

h1,
h2 {
  @include typo-title;

  @media (min-width: 1025px) {
    font-size: clamp(60px, calc(.05rem + 5vw), 5rem) !important;
  }

  @media (max-width: 1024px) {
    font-size: 60px;
  }

  @media (max-width: 991px) {
    font-size: 44px;
  }
}