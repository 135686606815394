.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  padding: 31px 48px;
  border-radius: 71px;
  color: $title;
  text-decoration: none;
  background: linear-gradient(45deg, #00FF94 0%, #7FFFC9 100%) padding-box,
  linear-gradient(90deg, rgba(167, 167, 167, 0.24) 0%, rgba(0, 0, 0, 0.02) 50%) border-box;

  box-sizing: border-box;
  border: 1px solid transparent;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-height: 88px;
  transition: outline 0.2s ease, border 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  }

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    margin: -1px;
    background: linear-gradient(45deg, #00FF94 0%, #7FFFC9 100%) padding-box,
    linear-gradient(90deg, rgba(167, 167, 167, 0.24) 0%, rgba(0, 0, 0, 0.02) 50%) border-box;
  }

  &__small {
    padding: 23px 32px;
    font-size: 1.125rem;
  }

  &__disabled {
    color: rgba(255, 255, 255, 0.48);
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.48);

    &:before {
      display: none;
    }
  }

  &__white {
    background: $white;
    border: 1px solid $grey;

    &:before {
      display: none;
    }
  }
}