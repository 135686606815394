// Layout
$max-width: 1720px;
$container-width: 1620px;
$border-radius: 48px;
$gap: 8px;


// Colors
$white: #fff;
$black: #000;
$title: #353D43;
$text: #0E1C26;
$green: #21EC97;
$grey: #E9E9E9;
$light-grey: #fafafa;
$faq-border: #D0D8DE;