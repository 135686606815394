section.about {
  margin-top: 90px;
  gap: 54px;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  .text {
    width: 34.538%;
    z-index: 2;

    @media (max-width: 991px) {
      width: 100%;
      text-align: center;

      .btn {
        display: none;
      }
    }
  }

  .media {
    width: 65%;

    @media (max-width: 991px) {
      margin: 0 auto;
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .title {
    font-size: 80px;
    line-height: 100%;

    @media (max-width: 1024px) {
      font-size: 60px;
    }

    @media (max-width: 991px) {
      font-size: 44px;
    }
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 32px;
    width: 432px;
    height: 637px;
    max-width: 432px;
    border: 1px dashed rgba(224, 224, 224, 1);
    border-radius: calc($border-radius / 2);
    background-color: $white;

    @media (max-width: 1280px) {
      max-width: 350px;
      height: 500px;
    }

    @media (max-width: 991px) {
      max-width: 100%;
      width: 100%;
      //height: calc(100vh - 450px);
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 32px;
      right: 32px;
      width: 22px;
      height: 28px;
      background-image: url("data:image/svg+xml,%3Csvg width='22' height='28' viewBox='0 0 22 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.0656 12.6626C20.459 11.2972 19.577 10.0716 18.475 9.06261L17.5656 8.22823C17.5347 8.20068 17.4976 8.18113 17.4574 8.17129C17.4172 8.16145 17.3752 8.16163 17.3351 8.17182C17.295 8.182 17.258 8.20187 17.2273 8.22969C17.1967 8.2575 17.1734 8.29242 17.1594 8.33136L16.7531 9.49698C16.5 10.2282 16.0344 10.9751 15.375 11.7095C15.3313 11.7564 15.2813 11.7689 15.2469 11.772C15.2125 11.7751 15.1594 11.7689 15.1125 11.7251C15.0687 11.6876 15.0469 11.6314 15.05 11.5751C15.1656 9.69386 14.6031 7.57198 13.3719 5.26261C12.3531 3.34386 10.9375 1.84698 9.16875 0.803234L7.87813 0.0438594C7.70938 -0.0561406 7.49375 0.0751093 7.50313 0.271984L7.57187 1.77198C7.61875 2.79698 7.5 3.70323 7.21875 4.45636C6.875 5.37823 6.38125 6.23448 5.75 7.00323C5.3107 7.53749 4.81278 8.02073 4.26562 8.44386C2.94783 9.45688 1.87629 10.7549 1.13125 12.2407C0.388043 13.7395 0.000906753 15.3897 0 17.0626C0 18.5376 0.290625 19.9657 0.865625 21.3126C1.42083 22.6094 2.22206 23.7863 3.225 24.7782C4.2375 25.7782 5.4125 26.5657 6.72188 27.1126C8.07812 27.6814 9.51562 27.9689 11 27.9689C12.4844 27.9689 13.9219 27.6814 15.2781 27.1157C16.5843 26.5721 17.772 25.7792 18.775 24.7814C19.7875 23.7814 20.5812 22.6126 21.1344 21.3157C21.7085 19.9725 22.003 18.5265 22 17.0657C22 15.5407 21.6875 14.0595 21.0656 12.6626Z' fill='black' fill-opacity='0.24'/%3E%3C/svg%3E%0A");
    }

    .bottom-info {
      padding: 20px 0 0 0;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      font-size: 20px;
      gap: 12px;
      opacity: 1;
    }
  }

  .slider {
    height: auto;
  }

  .swiper {
    overflow: visible;
  }

  .swiper-wrapper {
    position: absolute;
    top: 60px;
    left: 25%;

    @media (max-width: 991px) {
      //position: relative;
      top: 0;
      left: 0;
    }
  }

  .slide {
    position: relative;
    transition: opacity 0.3s ease, transform 0.7s ease, padding 0.3s ease;
    transform: scale(0.8) translateY(140px) translateX(-45px);
    padding: 0;
    opacity: 0;

    @media (max-width: 991px) {
      opacity: 1;
    }

    &.swiper-slide {
      &-active {
        transform: scale(1);
        opacity: 1;
        img {
          filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.3));
        }
      }

      &-prev,
      &-next {
        opacity: 35%;
      }
    }

    img {
      position: absolute;
      max-width: initial;
      width: 500px;
      top: -60px;
      left: -380px;
      //filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0));

      @media (max-width: 1280px) {
        width: 362px;
        left: -282px;
      }

      @media (max-width: 991px) {
        left: 0;
        top: 0;
        position: relative;
        max-width: 100%;
        width: 100%;
      }
    }

    //&.swiper-slide-next ~ .swiper-slide {
    //  opacity: 0;
    //
    //  @media (max-width: 991px) {
    //    opacity: 1;
    //  }
    //}
    //
    //&.swiper-slide:has(~ .swiper-slide-prev) {
    //  opacity: 0;
    //
    //  @media (max-width: 991px) {
    //    opacity: 1;
    //  }
    //}
  }

  .swiper-pagination {
    display: flex;
    gap: 10px;
    bottom: -53px;
    justify-content: center;
    

    @media (max-width: 480px) {
      width: 100%;
      display: flex;
    }

    .swiper-pagination-bullet {
      height: 30px;
      width: 30px;
      border-radius: 0;
      background-color: transparent;

      @media (max-width: 480px) {
        width: 100%;
      }

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.16);
        top: 50%;
        position: relative;
        transform: translateY(-50%);
      }

      &-active:after {
        background-color: $black;
      }
    }
  }

  & > .btn {
    @media (max-width: 991px) {
      width: 50%;
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }
}