section.collaborations {
  background: linear-gradient(257.46deg, #E4F2FF 0.51%, #F3F3F3 103.29%);
  border-radius: $border-radius;
  padding: 122px 0 0 0;
  overflow: hidden;

  @media (max-width: 991px) {
    padding-top: 56px;
    //margin-left: 8px;
    //margin-right: 8px;
    border-radius: 0;
  }

  .title {
    font-size: 63px;
    line-height: 75.6px;

    @media (max-width: 991px) {
      font-size: 44px;
    }

    @media (max-width: 991px) {
      padding: 0 8px;
    }
  }

  .text {
    max-width: 960px;
    margin-top: 44px;
    margin-bottom: 127px;
    font-size: 32px;
    line-height: 44.8px;

    @media (max-width: 991px) {
      padding: 0 8px;
      margin-top: 31px;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 30px;
      max-width: 80%;
    }

    @media (max-width: 480px) {
      max-width: 100%;
    }
  }

  .marquee-image {
    height: 364px;
    width: 100%;
    background-repeat: repeat-x;
    //background-size: 1000px auto;
    animation: marquee 120s infinite linear;
    //margin-bottom: -3.7%;
    transform: translateY(50px);

    &.lazy-background.visible {
      background-image: url("../img/collab-marquee.png?as=webp");
    }
  }
}
