section.hero {
  position: relative;
  padding-top: 240px;
  padding-bottom: 560px;
  margin-bottom: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-height: 920px) {
    //height: 100vh;
  }

  @media (max-width: 991px) {
    padding-top: 178px;
    padding-bottom: 2rem;
  }

  @media (max-width: 480px) {
    padding-top: 150px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @extend .radius-bottom-left;
    @extend .radius-bottom-right;

    @media (max-width: 991px) {
      border-radius: 0;
    }
  }
  //&:before {
  //  background: radial-gradient(55.72% 73.29% at 50% 16.49%, #131D20 0%, #101415 100%);
  //  z-index: -1;
  //  opacity: .5;
  //}
  &:after {
    background-image: url("../img/hero-bg.png?as=webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: -2;

    @media (max-width: 991px) {
      background-image: url("../img/hero-bg-mobile.png?as=webp");
    }
  }

  .hero-element {
    position: absolute;
    top: 210px;
    //animation: shake 0.5s;
    animation-iteration-count: infinite;

    @media (max-width: 991px) {
      position: relative;
      top: 0;
      width: 100%;
      transform: translateY(-50px);
    }
  }

  .nav {
    @media (max-width: 991px) {
      margin-top: -50px;
      padding: 0 24px;
      & > a {
        width: 50%;
        margin: 24px auto 0 auto;
      }
    }

    @media (max-width: 480px) {
      & > a {
        width: 100%;
      }
    }
  }
}
