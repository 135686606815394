section.join {
  align-items: center;
  border-radius: $border-radius;
  background: linear-gradient(223deg, #DCFAEB 0%, #DFE5F6 100%);
  padding: 80px;
  gap: 146px;

  @media (max-width: 1366px) {
    gap: 80px;
  }

  @media (max-width: 1024px) {
    padding: 60px 16px;
    gap: 10px;
  }

  @media (max-width: 991px) {
    padding: 40px 8px;
    //margin: 0 8px;
    flex-direction: column;
    gap: 40px;
    border-radius: 0;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: calc($gap * 4);
    width: 59%;

    @media (max-width: 991px) {
      position: relative;
      width: 100%;
      text-align: center;
      padding-bottom: 32px;
    }

    .title {
      line-height: 100%;
    }

    .btn {
      @media (max-width: 991px) {
        position: absolute;
        width: 100%;
        max-width: 50%;
        bottom: -83px;
        left: 50%;
        transform: translateX(-50%);
      }

      @media (max-width: 480px) {
        width: 100%;
        max-width: 90%;
        bottom: -83px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .media img {
    animation: floating 3s ease-in-out infinite;
  }
}
