.radius {
  border-radius: $border-radius;
}

.radius-top-left {
  border-top-left-radius: $border-radius;
}

.radius-top-right {
  border-top-right-radius: $border-radius;
}

.radius-bottom-right {
  border-bottom-right-radius: $border-radius;
}

.radius-bottom-left {
  border-bottom-left-radius: $border-radius;
}

.text-center {
  text-align: center;
}

.mx-auto {
  margin: 0 auto;
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.w-100 {
  width: 100% !important;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center;
}
.weight-800 {
  font-weight: 800;
}

.weight-700 {
  font-weight: 700;
}

.weight-600 {
  font-weight: 600;
}

.weight-500 {
  font-weight: 500;
}

.weight-300 {
  font-weight: 300;
}

.green {
  color: $green;
}

.direction-column {
  flex-direction: column;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.relative {
  position: relative;
}

.h-100 {
  height: 100%;
}

.opensea-logo {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.d-md {
  @media (min-width: 992px) {
    display: none !important;
  }
}

.hide-md {
  @media (max-width: 991px) {
    display: none !important;
  }
}

.mt-1 {
  margin-top: 1rem;
}