.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;

  & > .modal-body {
    position: relative;
    width: 360px;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    background: white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 25%);

    .close-modal {
      position: absolute;
      top: 8px;
      right: 8px;
      display: block;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.1001 4.89998C15.2001 0.999976 8.8001 0.999976 4.9001 4.89998C1.0001 8.79998 1.0001 15.2 4.9001 19.1C8.8001 23 15.1001 23 19.0001 19.1C22.9001 15.2 23.0001 8.79998 19.1001 4.89998ZM14.8001 16.2L12.0001 13.4L9.2001 16.2L7.8001 14.8L10.6001 12L7.8001 9.19998L9.2001 7.79998L12.0001 10.6L14.8001 7.79998L16.2001 9.19998L13.4001 12L16.2001 14.8L14.8001 16.2Z' fill='%23CCCCCC'/%3E%3C/svg%3E%0A");

      &:before {
        content: none;
      }
    }

    .title {
      padding-bottom: 1rem;
      font-weight: 700;
      font-size: 18px;
      line-height: 21.6px;
      border-bottom: 1px solid #F0F0F0;
    }
    .content {
      padding-top: 1rem;
      font-size: 1rem;
      line-height: 22.4px;
    }
  }
}

.open-modal, .close-modal {
  cursor: pointer;
}
