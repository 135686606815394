.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 62px;
  max-height: 62px;
  width: 62px;
  height: 62px;
  background-color: $white;
  border-radius: 50%;
}