@mixin typo-base() {
  font-family: Inter, sans-serif;
  font-size: 1.625rem;
  font-weight: 400;

  @media (max-width: 991px) {
    font-size: 20px;
  }
}

@mixin typo-anchor() {
  font-size: inherit;
  font-weight: 600;
  color: $green;
}

@mixin typo-title() {
  font-size: 5rem;
  font-weight: 900;
  letter-spacing: -0.1rem;
  color: $title;
}

@mixin typo-faq-title() {
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
}

@mixin typo-faq-text() {
  font-size: 22px;
  line-height: 33px;
}