header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 34px;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);

  @media (max-width: 1600px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 991px) {
    padding-left: 8px;
    padding-right: 8px;
    justify-content: center;
  }
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: calc($gap * 2);
    padding: 0;
    list-style: none;
  }
}