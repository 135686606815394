@import "/node_modules/swiper/swiper";
//@import "/node_modules/swiper/modules/pagination";

@import "reset";
@import "vars";
@import "utils";
@import "mixins";
@import "typography";
@import "animations";
@import "components/buttons";
@import "components/icons";
@import "components/modals";
@import "sections/header";
@import "sections/hero";
@import "sections/about";
@import "sections/join";
@import "sections/drops";
@import "sections/buy";
@import "sections/collaborations";
@import "sections/faq";
@import "sections/footer";

body {
  max-width: $max-width;
  margin: 0 auto;
  background-color: $light-grey;
  scroll-behavior: smooth;
}

main {
  overflow: hidden;
}

.container {
  max-width: $container-width;
  margin: 0 auto;

  @media (max-width: 991px) {
    //margin-left: 8px;
    //margin-right: 8px;
  }
}

section {
  margin-bottom: 160px !important;

  @media(max-width: 1600px) {
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 991px) {
    margin-bottom: 80px !important;
  }
}
