footer {
  position: relative;
  padding: 40px 8px 0 8px;
  border-radius: 48px 48px 0 0;
  background: radial-gradient(55.72% 73.29% at 50% 16.49%, #131D20 0%, #101415 100%);
  overflow: hidden;

  @media (max-width: 991px) {
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 0;
  }

  .media {
    display: flex;
    align-items: flex-end;
    width: 85%;
    animation: floating 3s ease-in-out infinite;
  }

  .title {
    margin-top: auto;
    margin-bottom: 64px;
    background: linear-gradient(228deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    font-size: clamp(5rem, calc(.05rem + 7.9375vw), 7.9375rem);
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    @media (max-width: 1024px) {
      font-size: 5rem;
    }

    @media (max-width: 991px) {
      font-size: 70px;
      line-height: 70px;
      text-align: center;
      margin-bottom: 0;
    }
  }

  .text {
    @media(max-width: 991px) {
      justify-content: center;
      align-items: center;
    }
  }

  .btn {
    font-weight: 500;
    font-size: 18px;
    padding: 18px 32px;
    color: $white;
    background: #282A31;
    border: 1px solid #565B5C;

    svg path {
      fill: $white;
    }

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .footer-links {
    max-width: 633px;
    margin-top: auto;
    margin-bottom: 67px;
    font-size: 1rem;
    line-height: 22.4px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.48);

    @media (max-width: 1024px) {
      margin-top: 50px;
    }

    @media (max-width: 991px) {
      justify-content: space-between;
      max-width: 100%;
      margin-bottom: 50px;
      width: 100%;
    }

    a {
      color: rgba(255, 255, 255, 0.48);

      &:before {
        background-color: rgba(255, 255, 255, 0.48);
      }
    }

    span {
      color: #fff;
    }
  }
}
