@keyframes scroll {
  to {
    transform: translateX(0);
  }
  from {
    transform: translateX(calc(-100% - 1rem));
  }
}

@keyframes marquee {
  0% {
    background-position: 0;
  }
  100% {
    background-position: calc(100vw * 2);
  }
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }
}

@keyframes rotating {
  0% { transform: rotate(0) }
  50%  { transform: rotate(0.2deg) }
  100%   { transform: rotate(0) }
}

@keyframes shake {
  0% { transform: translate(0.5px, 0.5px) rotate(0deg); }
  10% { transform: translate(-0.5px, -1px) rotate(-0.1deg); }
  20% { transform: translate(-1.5px, 0px) rotate(0.1deg); }
  30% { transform: translate(1.5px, 1px) rotate(0deg); }
  40% { transform: translate(0.5px, -0.5px) rotate(0.1deg); }
  50% { transform: translate(-0.5px, 1px) rotate(-0.1deg); }
  60% { transform: translate(-1.5px, 0.5px) rotate(0deg); }
  70% { transform: translate(1.5px, 0.5px) rotate(-0.1deg); }
  80% { transform: translate(-0.5px, -0.5px) rotate(-0.1deg); }
  90% { transform: translate(0.5px, 1px) rotate(0deg); }
  100% { transform: translate(0.5px, -1px) rotate(-0.1deg); }
}